import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { set } from '@/utils/vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    references: null,
    payment: null,
    instituciones: null,
    sectionSelected: null,
    sectionCategorySelected: null

  },
  mutations: {
    SET_REFERENCES: set('references'),
    SET_INSTITUCIONES: set('instituciones'),
    SET_PAYMENT: set('payment'),
    SET_SECTION_SELECTED: set('sectionSelected'),
    SET_SECTION_CATEGORY_SELECTED: set('sectionCategorySelected')
  },
  actions: {
    fetchReferences: async ({ commit }) => {
      const references = await axios.get('api/formulario/canales_referencia/')
      const referencesData = references.data
      commit('SET_REFERENCES', referencesData)
    },
    fetchInstituciones: async ({ commit }, payload) => {
      const institucionesResponse = await axios.get('api/instituciones/')
      const instituciones = institucionesResponse.data
      commit('SET_INSTITUCIONES', instituciones)
    },
    sendRegister: async ({ commit }, payload) => {
      await axios.post('api/formulario/formularios/', payload)
    }
  },
  getters: {
  },
  modules: {
  }
})
