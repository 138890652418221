import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import es from 'vuetify/lib/locale/es'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    values: {
      categorySection: 'M19.6491 5.2859L14.0001 8.5479V2.0249H10.0001V8.5479L4.35107 5.2859L2.35107 8.7509L7.99907 12.0119L2.35107 15.2729L4.35107 18.7379L10.0001 15.4769V21.9999H14.0001V15.4769L19.6491 18.7379L21.6491 15.2729L16.0011 12.0119L21.6491 8.7509L19.6491 5.2859Z'
    }

  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#0583DA',
        secondary: '#1FC1D0',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
        // usamedic: '#0583DA'
      }
    }
  },
  lang: {
    locales: { es },
    current: 'es'
  }
})
