import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'RegisterForm',
    component: () => import(/* webpackChunkName: "RegisterForm" */ '../views/RegisterForm.vue')
  },
  {
    path: '/terminosycondiciones',
    name: 'TermsAndConditions',
    component: () => import(/* webpackChunkName: "TermsAndConditions" */ '../views/TermsAndConditions.vue')
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
