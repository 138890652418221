<template>
  <v-dialog v-bind="options" v-model="show" max-width="450" persistent>
    <v-card rounded="lg">
      <v-card-title v-if="title" class="justify-center">{{ title }}</v-card-title>
      <v-card-text v-if="message" class="text-center black--text px-4 py-10 dialog__message"> {{ message }}</v-card-text>
      <v-card-actions class="pb-5 d-flex" :class="cancelText ? 'justify-space-evenly': 'justify-center'">
        <button v-if="cancelText" class="black--text mx-2 flex-shrink-1 dialog__btn dialog__btn--cancel" @click="cancel">
          {{ cancelText }}
        </button>
        <button class="white--text mx-2 flex-shrink-1 dialog__btn dialog__btn--accept" @click="agree">
          {{ acceptText }}
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmModal',
  data: () => ({
    show: false,
    resolve: null,
    reject: null,
    options: {},
    title: '',
    message: '',
    acceptText: 'Aceptar',
    cancelText: ''
  }),
  methods: {
    open ({ title, message, acceptText, cancelText, options }) {
      this.show = true
      this.title = title
      this.message = message
      this.acceptText = acceptText || 'ok'
      this.cancelText = cancelText
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree () {
      this.resolve(true)
      this.show = false
    },
    cancel () {
      this.resolve(false)
      this.show = false
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  &__message {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
  }

  &__btn {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    text-transform: none;
    flex-basis: 183px;
    min-height: 33px;
    border-radius: 5px;

    &--accept {
      border: 1px solid #0583DA;
      background-color: #0583DA;
      color: white;
    }

    &--cancel {
      border: 1px solid #E5E5E5;
      color: black;
    }
  }
}
</style>
