<template>
  <v-app id="app">
    <router-view ></router-view>
    <ConfirmModal ref="confirm"/>
  </v-app>
</template>

<script>
import ConfirmModal from '@/components/emergentesGlobales/ConfirmModal'

export default {
  name: 'App',
  components: {
    ConfirmModal
  },
  async mounted () {
    this.$root.$confirm = this.$refs.confirm.open
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;800&display=swap');
#app {
  overflow: hidden !important;
  & * {
        font-family: Poppins, sans-serif;
  }
}
</style>
